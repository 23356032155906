import {
  Stack,
  Link,
  HStack,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { Link as RouterLink, useActionData } from "@remix-run/react";
import type { ActionArgs, MetaFunction } from "@remix-run/node";
import { T, t } from "~/libs/i18n";
import { withZod } from "@remix-validated-form/with-zod";
import type {
  FormValidationErrorResponse,
  ServerErrorResponse,
} from "types/remix";
import { z } from "zod";
import { ValidatedForm } from "remix-validated-form";
import { ValidatedInput } from "~/components/forms/validated-inputs.tsx";
import { SubmitButton } from "~/components/forms/form-buttons.tsx";
import {
  AuthSession,
  isAuthChallenge,
  SESSION_KEYS,
} from "~/utils/session.server.ts";
import { json, redirect } from "@remix-run/node";
import { IdentityApi } from "~/api/identity-api.ts";
import { $path } from "remix-routes";

export const meta: MetaFunction = () => {
  return [
    { title: `${t("Login")} - Preventure` },
    { name: "description", content: "Preventure dashboard" },
  ];
};

export const loginFormValidator = withZod(
  z.object({
    email: z
      .string()
      .min(1, { message: "Email is required" })
      .email("Must be a valid email"),
    password: z.string().min(1, { message: "Password is required" }),
  }),
);

export async function action({ request }: ActionArgs) {
  const formData = await request.formData();
  const submission = await loginFormValidator.validate(formData);

  if (submission.error) {
    const validationError: FormValidationErrorResponse = {
      type: "FORM_VALIDATION_ERROR",
      fieldErrors: submission.error.fieldErrors,
    };
    return validationError;
  }

  const { email, password } = submission.data;

  const response = await IdentityApi.LoginWithEmail(request, {
    email,
    password,
  });

  if (!response.ok) {
    const error: ServerErrorResponse = {
      type: "SERVER_ERROR",
      error: response.error,
    };
    return error;
  }

  const responseData = response.data;

  const session = await new AuthSession(request);
  const redirectTo = session.get(SESSION_KEYS.POST_LOGIN_RETURN_TO_PATH);

  if (isAuthChallenge(responseData)) {
    if (responseData.challenge === "SOFTWARE_TOKEN_MFA") {
      session.set(SESSION_KEYS.AUTH_MFA_CHALLENGE, {
        ...response.data,
        redirectTo,
      });
      throw redirect($path("/login/mfa"), await session.commit());
    }

    if (responseData.challenge === "NEW_PASSWORD_REQUIRED") {
      session.set(SESSION_KEYS.AUTH_NEW_PASSWORD_CHALLENGE, {
        ...response.data,
      });
      throw redirect($path("/login/change-password"), await session.commit());
    }
  }

  return await session.login({
    response: json(responseData, response.init),
    redirectTo,
  });
}

export default function AuthLogin() {
  const actionData = useActionData<typeof action>();
  return (
    <Stack>
      <Stack mb={8}>
        <Text textStyle={"heading"}>
          <T>Log in</T>
        </Text>
        <HStack>
          <Text size={"label"}>
            <T>New to Preventure?</T>
          </Text>
          <Link as={RouterLink} to={"/sign-up"} data-testid={"signup-link"}>
            <T>Sign up</T>
          </Link>
        </HStack>
      </Stack>
      <ValidatedForm validator={loginFormValidator} method={"post"}>
        <Stack w={"md"} maxW={"full"}>
          {actionData?.type === "SERVER_ERROR" && (
            <Alert status={"error"} data-testid={"login-error-alert"}>
              <AlertIcon />
              <AlertDescription>
                {actionData?.error.description}
              </AlertDescription>
            </Alert>
          )}
          <ValidatedInput name={"email"} type={"email"} label={t("Email")} />
          <ValidatedInput
            name={"password"}
            type={"password"}
            label={t("Password")}
          />

          <Box textAlign={"right"}>
            <Link
              as={RouterLink}
              to={"/password-reset"}
              data-testid={"forgot-password-link"}
            >
              <T>Forgot password?</T>
            </Link>
          </Box>

          <Stack
            w={"full"}
            justify={"space-between"}
            spacing={4}
            px={{ sm: 2, md: 12 }}
            py={4}
          >
            <SubmitButton w={"auto"} data-testid={"login-button"}>
              <T>Log in</T>
            </SubmitButton>
          </Stack>
        </Stack>
      </ValidatedForm>
    </Stack>
  );
}
